<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Investir dans un Liquidity Pool</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Un investisseurs qu dispose de plusieurs tokens différents peut les placer dans des Liquidity Pool afin de
			percevoir une rémunération additionnelle à chaque transaction dans les liquidity Pool auquel il participe.
			<br /> <br /> Le Principe consiste à placer dans un liquidity Pool deux tokens et de recevoir en
			échange des parts de ce Liquidity Pool. A chaque transaction le Liquidity Pool conserve 0.03% des tokens qu'il echange,
			ce qui constitue une rémunération pour les apporteurs de liquidté.
			<br /> <br /> Lorsque l'investisseur désire quitter le liquidity Pool il retire ses parts et récupére
			les deux tokens qui constitue le Liquidity Pool
			<br /> <br />

			Voir <a href="https://fraction.immo/post/rentable"> cet article pour plus de détail sur le Smart Contract de Fraction.re</a>
			<br /> ou <a href="https://academy.binance.com/fr/articles/what-is-an-automated-market-maker-amm"> cet article générique sur les AMM et les Liquidity Pool</a>

		<h2>
			Bénéfice et risque particulier</h2>
		<p>

			Le Bénéfice pour les apporteurs de Liquidity Pool est de 0.03% par transaction.
			<br />Il existe un risque de pertes intermittentes, ce qui destine ce smart contract à un public averti.
			<br />
			<br />Voir <a href="
			https://stakepool.fr/articles/tout-comprendre-aux-pertes-intermittentes-impermanent-loss"> cet article sur le risque de perte intermittentes ( ou perte non permanente selon la traduction)</a>
			<br /> <br />Avertissement : si vous ne comprennez pas le fonctionnement d'un AMM et les risques d'investir dans des parts ne participez pas à un Liquidity Pool.
		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Emma dispose des tokens Berlcoin et de RE59100F001. Elle désire rejoindre le Liquidity Pool de ces deux tokens.
			<br /> Les deux tokens ont sensiblement la même valeur, Emma va investir 10 Berlcoins et 10 RE59100F001 dans un premier temps et
			dans un second temps réduire ses parts.
			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/pool-1.png" />
				<br /> <br /> <b> Le compte de Emma, elle ne dispose d'aucune part dans un Liquidity Pool </b><br />
			</div>

		</div>


		<h3 class="mt-12">
			Rejoindre un Liquidity Pool</h3>


		<p>Dans la section <b>Liquidity Pool</b>.</p>


		<div class="row ">

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-3.png" /><br />
				<br /> <br />
				<b>Emma sélectionne la section Liquidity Pool, la liste des pools qu'elle peut rejoindre en fonction des tokens dont elle dispose s'affiche.
				</b> <br />
			</div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-4.png" /><br />
				<br /> <br />
				<b>Emma sélectionne le Liquidity Pool Berlcoin / RE59100F001, le bouton d'intégration s'affiche.
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-5.png" /><br />
				<br /> <br />
				<b>Emma saisie son code de sécurité et valide
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-6.png" /><br />
				<br /> <br />
				<b>Dans la Dashboard, Emma constate qu'elle à rejoint ce Liquidity Pool.
				</b> <br />
			</div>
		</div>

		<h3 class="mt-12">
			Investir dans un Liquidity Pool</h3>


		<p>Dans la section <b>Liquidity Pool</b>.</p>


		<div class="row ">



			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-7.png" /><br />
				<br /> <br />
				<b>Emma sélectionne le Liquidity Pool Berlcoin / RE59100F001
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-8.png" /><br />
				<br /> <br />
				<b>Le menu de dépôt ou de retrait s'affiche, Emma sélectionne DEPOT
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-9.png" /><br />
				<br /> <br />
				<b>Emma choisie de placer 10 Berlcoins, la contrevaleur en RE59100F001 s'ajuste automatiquement.
					Elle saisie son code et valide.
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-10.png" /><br />
				<br /> <br />
				<b>Dans le Dashboard Emma constate qu'elle dispose désormais de 10 parts du Liquidty Pool et de 10 Berlcoins et 10 RE59100F001 en moins.
				</b> <br />
			</div>
		</div>

		<h3 class="mt-12">
			Retrait d'un Liquidity Pool</h3>


		<p>Dans la section <b>Liquidity Pool</b>.</p>


		<div class="row ">



			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-11.png" /><br />
				<br /> <br />
				<b>Emma sélectionne le Liquidity Pool Berlcoin / RE59100F001
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-12.png" /><br />
				<br /> <br />
				<b>Le menu de dépôt ou de retrait s'affiche, Emma sélectionne RETRAIT
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-13.png" /><br />
				<br /> <br />
				<b>Elle indique voulir récupérer 5 parts, saisie son code et valide.
				</b> <br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/pool-14.png" /><br />
				<br /> <br />
				<b>Dans le Dashboard Emma constate qu'elle dispose désormais de 5 parts du Liquidty Pool et de 5 Berlcoins et 5 RE59100F001 en plus.

				</b> <br />
			</div>


		</div>



		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Emma a payé 0.00001 XLM (ici à 0.20 euro pour 1 XLM ) pour intégrer un Liquidity Pool, 0.00001 XLM pour le dépôt et 0.001 XML pour le retrait
				<br /> <br /> Au final moins de 1 centime d'euro.
			</p>
		</div>

		<div class="row ">
			<h3 class="mt-12">
				A savoir</h3>
			<p> Dans cet exemple, Emma à déposé et retiré ses tokens à la suite, il n'y a donc pas eu de variation de cours de la part du Liquidity Pool,
				elle aurait aussi pu recevoir un peu moins de Berlcoin et un peu plus de RE59100F001 ou l'inverse.
				Mais dans tous les cas au minimum le même nombre cumulé de tokens (20 dans cet exemple) et un peu plus avec les commissions de transaction.
			</p>
		</div>



	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
